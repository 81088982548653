<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <small v-if="+$route.query.id">
          last edited: {{ formData.preview_updated_at }}
        </small>
        <h2>Sevice Request Information</h2>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="+$route.query.id"
          :variant="openSection ? 'primary' : 'outline-primary'"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle mr-2"
          @click="openSection = !openSection"
        >
          <feather-icon icon="MessageCircleIcon" size="16" />
        </b-button>
        <b-button
          v-b-modal.modal-manage
          v-if="+$route.query.id"
          variant="danger"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="Trash2Icon" size="16" />
        </b-button>

        <Modal modalId="modal-manage" @onChange="(v) => onDelete()" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" :md="openSection ? '9' : '12'">
        <b-card>
          <b-tabs v-model="activeTab" fill>
            <b-tab title="Information">
              <b-row>
                <b-col class="text-right">
                  <b-button
                    type="button"
                    :class="hasJobOrder && getRole ? 'mr-2' : ''"
                    variant="outline-danger"
                    @click="goBack"
                  >
                    Back
                  </b-button>
                  <b-button
                    v-if="hasJobOrder && getRole"
                    type="button"
                    variant="success"
                    @click="onEdit()"
                  >
                    Edit
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3">
                  <b-form-group label="Ticket No." label-for="v-ticket-no">
                    <b-form-input
                      disabled
                      v-model="formData.ticket_no"
                      id="v-ticket-no"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Company" label-for="v-company">
                    <b-form-input
                      v-model="formData.company_name"
                      id="v-company"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Contract No." label-for="v-contract_no">
                    <b-form-input
                      v-model="formData.contract_refnum"
                      id="v-contract_no"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="PO No." label-for="v-po_no">
                    <b-form-input
                      v-model="formData.po_no"
                      id="v-po_no"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3">
                  <b-form-group
                    label="Processed By"
                    label-for="v-assigned_user"
                  >
                    <b-form-input
                      disabled
                      id="v-assigned_user"
                      v-model="formData.assigned_user"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Service Type" label-for="v-service_type">
                    <b-form-select
                      id="v-service_type"
                      disabled
                      v-model="formData.service_type"
                      :options="[
                        {
                          text: 'Preventive Maintenance',
                          value: 'preventive maintenance',
                        },
                        { text: 'Project', value: 'project' },
                        { text: 'Maintenance', value: 'maintenance' },
                        { text: 'Chargeable', value: 'chargeable' },
                      ]"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group
                    label="Service Status"
                    label-for="v-service_status"
                  >
                    <v-select
                      disabled
                      id="v-service_status"
                      v-model="formData.service_status"
                      :options="[
                        'New',
                        'Scheduled',
                        'In-House Service',
                        'On-site Service',
                        'Hold',
                        'Other',
                        'Cancel',
                        'Completed',
                      ]"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Priority" label-for="v-urgency_level">
                    <v-select
                      disabled
                      v-model="formData.urgency_level"
                      :options="['Critical', 'High', 'Medium', 'Low']"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Report Date"
                    label-for="v-reported_date-date"
                  >
                    <b-form-input
                      v-model="formData.preview_reported_date"
                      id="v-reported_date-date"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Job Request Date"
                    label-for="v-job_request-date"
                  >
                    <b-form-input
                      disabled
                      id="v-job_request-date"
                      type="date"
                      v-model="formData.preview_job_request_date"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    label="Problem Description"
                    label-for="v-problem_description"
                  >
                    <b-form-textarea
                      disabled
                      id="v-problem_description"
                      v-model="formData.problem_description"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <h2>Job Site</h2>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    label="Site/Branch/Outlet Name"
                    label-for="v-site_id"
                  >
                    <b-form-input
                      disabled
                      v-model="formData.site_id"
                      id="v-site_id"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Building/Block/House No. and Street Name"
                    label-for="v-addr1"
                  >
                    <b-form-input
                      disabled
                      v-model="formData.addr1"
                      id="v-addr1"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Unit No. and Building Name"
                    label-for="v-addr2"
                  >
                    <b-form-input
                      disabled
                      v-model="formData.addr2"
                      id="v-addr2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Country" label-for="v-country">
                    <v-select
                      disabled
                      v-model="formData.country"
                      :options="$store.getters['hbaseGeneral/getAllCountries']"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Postal Code" label-for="v-postal">
                    <b-form-input
                      disabled
                      v-model="formData.postal"
                      id="v-postal"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <h2>Contact Information</h2>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3">
                  <b-form-group label="Reported By" label-for="v-issued_by">
                    <b-form-input
                      disabled
                      v-model="formData.issued_by"
                      id="v-issued_by"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Contact Person" label-for="v-poc">
                    <b-form-input disabled v-model="formData.poc" id="v-poc" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Mobile" label-for="v-mobile">
                    <b-form-input
                      disabled
                      v-model="formData.mobile"
                      id="v-mobile"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Email" label-for="v-email">
                    <b-form-input
                      disabled
                      v-model="formData.email"
                      id="v-email"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>Item(s) for Servicing</h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <JobRequestDetails
                      isDisabled
                      :data-source="dataSourceDetails"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>Customer's (Problem) Photos</h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <JobRequestImages
                      isDisabled
                      :data-source="dataSourceImages"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group>
                    <h2>
                      Job Assignment Crew
                      <span v-if="!hasJobOrder">
                        :<a
                          href="javascript:void(0)"
                          @click="onViewJobOrder(formData.joborder)"
                        >
                          {{ jobOrder }}
                        </a>
                      </span>
                    </h2>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="text-right"
                  v-if="isCreateJobEnabled"
                >
                  <b-form-group>
                    <b-button
                      v-b-modal.jobs-action
                      type="button"
                      variant="primary"
                      size="sm"
                    >
                      Create Job
                    </b-button>
                  </b-form-group>

                  <Modal
                    modalId="jobs-action"
                    @onChange="(v) => onCreateJob()"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Select Job" label-for="v-job">
                    <v-select
                      disabled
                      id="v-job"
                      label="name"
                      v-model="formData.job_id"
                      :options="jobs"
                      :reduce="(jobs) => +jobs.id"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="2">
                  <b-form-group label="Job Type" label-for="v-job_type">
                    <v-select
                      disabled
                      id="v-job_type"
                      v-model="formData.job_type"
                      :options="jobTypes"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" :md="getRole ? '2' : '4'">
                  <b-form-group
                    label="Service Location"
                    label-for="v-service_location"
                  >
                    <b-form-input
                      disabled
                      id="v-service_location"
                      v-model="serviceLocation"
                      class=""
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="2" v-if="getRole">
                  <b-form-group label="Standard Price" label-for="v-price">
                    <b-form-input
                      class="text-right"
                      disabled
                      v-model="formData.standard_price"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Job ETA" label-for="v-job_eta">
                    <b-form-input
                      disabled
                      v-model="formData.preview_job_eta"
                      id="v-job_eta"
                      type="datetime-local"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Job ETD" label-for="v-job_etd">
                    <b-form-input
                      disabled
                      v-model="formData.preview_job_etd"
                      id="v-job_etd"
                      type="datetime-local"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Engineer Assigned"
                    label-for="v-technician_id"
                  >
                    <b-form-input
                      disabled
                      v-model="formData.technician"
                      id="v-technician_id"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Description">
                    <quill-editor
                      disabled
                      ref="quillEditorRef"
                      v-model="content"
                      id="v-job_description"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <h2>Other Information</h2>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group label="GST" label-for="v-gst">
                    <b-form-input disabled v-model="formData.gst" id="v-gst" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Instructions/Remarks"
                    label-for="v-remarks"
                  >
                    <b-form-textarea
                      disabled
                      v-model="formData.remarks"
                      id="v-remarks"
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <span v-if="$route.query.id">
              <b-tab title="Notes">
                <Notes ref="notesRef" module="SRQ" :header-id="getFormDataID" />
              </b-tab>
              <b-tab title="Documents">
                <Documents
                  ref="documentsRef"
                  module="SRQ"
                  :header-id="getFormDataID"
                />
              </b-tab>
              <b-tab title="Logs">
                <Logs module="servicerequest" />
              </b-tab>
              <b-tab title="Comments">
                <Comments module="servicerequest" />
              </b-tab>
            </span>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col cols="12" :md="openSection ? '3' : '12'" v-if="openSection">
        <b-row>
          <b-col>
            <b-card>
              <b-tabs fill>
                <b-tab active>
                  <template #title>
                    <small>Comments</small>
                  </template>
                  <JobRequestCommentsPanel />
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Logs</small>
                  </template>
                  <JobRequestLogsPanel />
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Approval</small>
                  </template>
                  <JobRequestApproval />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import usersApi from '@api/users';
import jobsApi from '@api/jobs';
import jobRequestApi from '@api/jobrequest_headers';
import { queryParameters } from '@/schema';
// @todo change from utils classes to global-function
import { UtilsDate, unixToDisplayDateTime } from '@/utils/classes/dates';
import JobRequestDetails from './components/JobRequestDetails';
import JobRequestImages from './components/JobRequestImages';
import JobRequestComments from './components/JobRequestComments';
import JobRequestLogs from './components/JobRequestLogs';
import JobRequestLogsPanel from './components/JobRequestLogsPanel';
import JobRequestCommentsPanel from './components/JobRequestCommentsPanel';
import JobRequestApproval from './components/JobRequestApproval';
import { getUserData } from '@/auth/utils';

export default {
  name: 'ServiceRequestManage',
  components: {
    JobRequestDetails,
    JobRequestImages,
    JobRequestComments,
    JobRequestLogs,
    JobRequestLogsPanel,
    JobRequestCommentsPanel,
    JobRequestApproval,
  },
  data: () => ({
    openSection: false,
    activeTab: 0,
    dataSourceDetails: [],
    dataSourceImages: [],
    dataSourceJobs: [],
    formData: {},
    jobTypes: [
      'Configure',
      'Dismantle',
      'Inspect',
      'Install',
      'Renew',
      'Repair',
      'Replace',
      'Service',
      'Survey Job Type',
    ],
    users: [],
    jobs: [],
    preview_job_eta: null,
    content: '',
  }),
  watch: {
    activeTab(v) {
      if (v === 1) {
        this.$refs.notesRef.loadNotes();
      }

      if (v === 2) {
        this.$refs.documentsRef.loadDocuments();
      }
    },
  },
  computed: {
    isServiceStatus: {
      get() {
        return this.formData.service_status !== 'Potential';
      },
    },
    hasJobOrder: {
      get() {
        return this.formData.joborder_header_id === 0;
      },
    },
    jobOrder: {
      get() {
        const { joborder } = this.formData;

        if (joborder && joborder.data) {
          const { data } = joborder;
          return `${data.ticket_no} (${this.getJobStatus(data.job_status)})`;
        }
      },
    },
    serviceLocation: {
      get() {
        const { service_location } = this.formData;

        return service_location === 'O' ? 'On-Site' : 'Remote';
      },
    },
    getFormDataID: {
      get() {
        return parseInt(this.formData.id);
      },
    },
    getRole: {
      get() {
        const userData = getUserData();
        const { role } = userData;

        const roles = ['admin', 'custadmin', 'qcsemployee'];
        return roles.includes(role);
      },
    },
    isCreateJobEnabled: {
      get() {
        return this.hasJobOrder && this.isServiceStatus && this.getRole;
      },
    },
  },
  mounted() {
    this.loadJobs();
    if (+this.$route.query.id) {
      this.loadJobRequest();
    }
  },
  methods: {
    loadJobs() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      jobsApi
        .list(params)
        .then(({ data }) => {
          this.jobs = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadJobRequest() {
      jobRequestApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          const toDisplayDate = (date) => {
            return new UtilsDate(date).unixToInputDateTime();
          };

          if (data != null) {
            const {
              updated_at,
              reported_date,
              job_request_date,
              job_description,
              job_eta,
              job_etd,
              standard_time,
              standard_price,
            } = data;

            const { quill } = this.$refs.quillEditorRef;
            if (quill) {
              quill.root.innerHTML = job_description;
            }
            this.content = job_description;

            data.preview_updated_at = unixToDisplayDateTime(updated_at);

            if (data.reported_date) {
              data.preview_reported_date = unixToDisplayDateTime(reported_date);
            }

            if (job_request_date) {
              const [splitDate] = toDisplayDate(job_request_date).split('T');
              data.preview_job_request_date = splitDate;
            }

            if (job_eta) {
              data.preview_job_eta = toDisplayDate(job_eta);
            }

            if (job_etd) {
              data.preview_job_etd = toDisplayDate(job_etd);
            }

            data.hrs = Math.floor(standard_time / 60);
            data.mins = standard_time % 60;

            if (data.jobrequest_details.data) {
              this.dataSourceDetails = data.jobrequest_details.data;
            }

            if (data.jobrequest_images.data) {
              this.dataSourceImages = data.jobrequest_images.data;
            }

            data.standard_price = this.moneyFormat(standard_price).replace(
              '$',
              ''
            );
            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onCreateJob() {
      jobRequestApi
        .createJobs(this.formData)
        .then(({ data }) => {
          if (data) {
            this.loadJobRequest();
            this.toastConfig('Job created successfully');
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSaveJob() {
      const data = this.formData;

      const getDetails = (dataSource, opt = { addOn: false }) => {
        const details = [];
        for (let i = 0; i < dataSource.length; i++) {
          const detail = dataSource[i];
          if (opt.addOn) {
            if (typeof detail.id !== 'number') {
              detail.reference_id = detail.id;
            }
          }

          if (opt.isJob) {
            detail.id = parseInt(detail.id);
            detail.job_eta = new UtilsDate(detail.preview_job_eta).dateToUnix();
            detail.job_etd = new UtilsDate(detail.preview_job_etd).dateToUnix();
          }

          details.push(detail);
        }

        return details;
      };

      data.details = getDetails(this.dataSourceDetails);
      data.images = getDetails(this.dataSourceImages, { addOn: true });

      jobRequestApi
        .saveJobs(data)
        .then(({ data }) => {
          if (data) {
            this.loadJobRequest();
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSaveCrew(item) {
      const { index, user, tech } = item;
      const newSource = [...this.dataSourceJobs];
      newSource[index].crew = user;
      newSource[index].technician_id = tech;
      this.dataSourceJobs = [];

      this.dataSourceJobs = newSource;
    },
    onEdit() {
      const url = `/pages/services-tools/service-request/manage?id=${this.$route.query.id}`;
      this.$router.push(url);
    },
    onViewJobOrder(joborder) {
      const { data } = joborder;
      if (data) {
        const url = `/pages/services-tools/job-order/view?id=${data.id}`;

        window.open(url, '_blank');
      }
    },
    goBack() {
      this.$router.push(`/pages/services-tools/service-request`);
    },
  },
};
</script>

<style lang="scss">
.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>
