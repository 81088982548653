<template>
  <span>
    <div class="p-1" v-for="msg in timelineMsg" :key="msg.id">
      <strong style="margin-bottom: 0px">{{ msg.user_id }}:</strong>
      <small>
        {{ getDateTime(msg.updated_at) }}
      </small>
      <div>{{ msg.remarks }}</div>
    </div>
    <div class="p-1">
      <b-row style="margin-bottom: 5px">
        <b-col>
          <b-form-textarea
            v-model="comment"
            id="textarea-default"
            placeholder="Comment..."
            rows="4"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="success" size="sm" block @click="onSubmit">
            SEND <feather-icon icon="SendIcon" size="16" />
          </b-button>
        </b-col>
      </b-row>
    </div>
  </span>
</template>

<script>
import timelineApi from '@api/timelines';
import timelineHeaderApi from '@api/timeline_headers';
// @todo change from utils classes to global-function
import { UtilsDate, unixToDisplayDateTime } from '@/utils/classes/dates';
import { queryParameters } from '@/schema';

export default {
  name: 'RFQCommentsSection',
  data: () => ({
    timelineMsg: [],
    comment: null,
    timelineHeaderId: 0,
  }),
  mounted() {
    this.loadTimelineHeader();
  },
  methods: {
    loadTimelineHeader() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `header_id=${this.$route.query.id}`;

      timelineHeaderApi
        .list(params)
        .then(({ data }) => {
          if (data && data.length > 0) {
            this.timelineHeaderId = data[0].id;
            this.loadTimelines(this.timelineHeaderId);
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTimelines(id) {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.sort = '-created_at';
      params.filterExt = `header=${id}`;

      timelineApi
        .list(params)
        .then(({ data }) => {
          this.timelineMsg = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    getDateTime(dt) {
      return unixToDisplayDateTime(dt);
    },
    onSubmit() {
      const formData = {
        id: 0,
        header_id: +this.timelineHeaderId,
        remarks: this.comment,
        lastsent: new UtilsDate().dateToUnix(),
      };

      if (formData.remarks) {
        timelineApi
          .add(formData)
          .then(({ data }) => {
            if (data) {
              this.loadTimelines(this.timelineHeaderId);
              this.comment = null;
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
  },
};
</script>

<style></style>
